import * as React from "react"
import { HeadFC } from "gatsby"
import {StaticImage} from "gatsby-plugin-image";

import Layout from "../components/Layout";
import CustomLink from "../components/shared/custom-link/CustomLink";

const browser = typeof window !== "undefined" && window;

const NotFoundPage = () => {
  return (
    browser && (
      <Layout>
        <section className={'bg-tertiary-40 flex flex-col justify-center items-center pt-20 px-5 sm:px-10 pb-24'}>
          <StaticImage loading={'eager'} placeholder={'blurred'} className={'mb-9'} src={'../assets/404.png'} alt={'Zabłądziłeś? Zamów MYTRACKO lub przejdź na stronę główną'} />
          <div className={'flex flex-col 2xs:flex-row gap-2.5'}>
            <CustomLink className={'mtr-btn-small xs:mtr-btn sm:mtr-btn-large mtr-btn-[secondary]'} isInternal to={'/zamow'}>
              <span className={'uppercase'}>zamów urządzenie</span>
            </CustomLink>
            <CustomLink className={'mtr-btn-small xs:mtr-btn sm:mtr-btn-large mtr-btn-outline-[secondary]'} isInternal to={'/'}>
              <span className={'uppercase text-white'}>strona głowna</span>
            </CustomLink>
          </div>
        </section>
      </Layout>
    )
  )
}

export default NotFoundPage

export const Head: HeadFC = () =>
  <>
    <title>Błąd 404 - MYTRACKO</title>
    <meta name="robots" content="noindex, nofollow"/>
  </>
